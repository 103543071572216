<template>
  <div>
    <Modal :value="visible"
           title="选择库管"
           footer-hide
           width="980"
           @on-visible-change="onVisibleChange">
      <div class="selectStorage">
        <div class="selectStorage-topbar">
          <div class="flex a-center">
            <Input v-model="searchValue"
                   placeholder="库管名称"
                   search
                   @on-search="onSearch"
                   style="width:280px" />
          </div>
          <Button @click.stop="onClickCreate">新建库管</Button>
        </div>
        <Table :height="500"
               border
               :columns="columns"
               :data="data"
               @on-row-click="onRowClick">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop="onClickEdit(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">编辑</Button>
          </template>
        </Table>
        <Page class="m-t-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
        <div class="selectStorage-btn">
          <Button type="primary"
                  class="m-r-25"
                  :disabled="!selectStorage"
                  @click.stop="onClickConfirm">确认</Button>
          <Button @click.stop="onClickCancle">取消</Button>
        </div>
      </div>
    </Modal>
    <StorekeeperCreateModal v-model="createVisible"
                            :manageId.sync="createStorageId"
                            @on-click-confirm="onClickConfirmStorage"></StorekeeperCreateModal>
  </div>
</template>

<script>
import { getStorekeeperList } from '../../services/storekeeperapi';
import StorekeeperCreateModal from './StorekeeperCreateModal';
export default {
  components: { StorekeeperCreateModal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean
  },
  data () {
    return {
      searchValue: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      columns: [
        {
          title: ' ',
          width: 45,
          align: 'center',
          render: (h, params) => {
            return h('Radio', {
              props: {
                value: params.row.checked
              },
              on: {
                'on-change': () => {
                  this.onRowClick(params.row, params.index)
                }
              }
            });
          }
        },
        {
          title: '姓名',
          key: 'manageName',
          tooltip: true,
          width: 240
        },
        {
          title: '电话',
          key: 'managePhone',
          tooltip: true,
          minWidth: 240
        },
        {
          title: '操作',
          slot: 'operation',
          width: 60,
          align: 'center'
        }
      ],
      data: [],
      selectStorage: null,

      createVisible: false,
      createStorageId: 0,
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getStorekeeperList()
    },
    onSearch () {
      this.pageNum = 1
      this.getStorekeeperList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getStorekeeperList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getStorekeeperList()
    },
    // 获取库管列表
    getStorekeeperList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchValue) params.manageName = this.searchValue
      getStorekeeperList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          item.checked = false
          return item
        })
      })
    },
    // 点击某一行
    onRowClick (row, index) {
      this.data.forEach(item => {
        this.$set(item, 'checked', false);
      });
      this.data[index].checked = true;
      this.selectStorage = row;
    },
    // 点击确定
    onClickConfirm () {
      this.$emit('change', false)
      this.$emit('on-click-confirm', this.selectStorage)
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
      this.$emit('on-click-cancle')
    },
    // 显示状态改变
    onVisibleChange (boo) {
      if (!boo) this.onClickCancle()
    },
    // 点击新建库管
    onClickCreate () {
      this.createStorageId = 0
      this.createVisible = true
    },
    // 点击编辑
    onClickEdit (row) {
      this.createStorageId = row.manageId
      this.createVisible = true
    },
    onClickConfirmStorage () {
      this.createVisible = false
      this.getStorekeeperList()
    }
  },
}
</script>

<style lang='scss' scoped>
.selectStorage {
  .selectStorage-topbar {
    padding: 4px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ::v-deep {
    .ivu-table-cell {
      padding: 0 8px;
    }
  }
  .selectStorage-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
</style>