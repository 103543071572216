<template>
  <div>
    <Modal :value="visible"
           :title="storageId?'编辑':'新增'+'仓库'"
           footer-hide
           @on-visible-change="onModalClose"
           width="720">
      <div class="createStorage">
        <ul class="createStorage-form">
          <li class="createStorage-li createStorage-pic">
            <p>仓库照片:</p>
            <div class="createStorage-pic-upload"
                 @click.stop="uploadPic">
              <template v-if="coverList.length===0">
                <Icon custom="i-icon icon-upload_pic"
                      size="42"
                      class="m-b-5"></Icon>
                上传图片
              </template>
              <template v-else>
                <img :src="getDefaultCover(coverList).url">
              </template>
            </div>
          </li>
          <li class="createStorage-li">
            <p><span style="color:#D0021B">*</span>仓库名称:</p>
            <Input v-model="name"
                   placeholder="仓库名称"
                   maxlength="20"
                   style="width:220px" />
          </li>
          <li class="createStorage-li">
            <p>仓库地址:</p>
            <div class="flex-1">
              <AddressChooseComponent :width="148"
                                      :addressinfo="location"></AddressChooseComponent>
              <Input v-model="address"
                     placeholder="详细地址"
                     maxlength="120"
                     style="width:100%;margin-top:15px" />
            </div>
          </li>
        </ul>
        <div class="createStorage-btn">
          <Button type="primary"
                  class="m-r-20"
                  @click.stop="onClickConfirm">确认</Button>
          <Button @click.stop="onClickCancle">取消</Button>
        </div>
      </div>
    </Modal>
    <Modal v-model="uploadPicVisible"
           title="上传图片"
           footer-hide
           width="560">
      <div class="uploadPic">
        <ImageListComponent uploadpath="/company/about/"
                            :data="imageList"
                            @upload-success="uploadCoverSuccess"></ImageListComponent>
        <div class="uploadPic-btn">
          <Button type="primary"
                  class="m-r-20"
                  @click.stop="onClickConfirmPic">确认</Button>
          <Button @click.stop="onClickCanclePic">取消</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createStorage, editStorage, getStorageDetail } from '../../services/storageapi';
import AddressChooseComponent from '../common/AddressChooseComponent';
import ImageListComponent from '../common/ImageListComponent';
export default {
  components: { AddressChooseComponent, ImageListComponent },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    storageId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:storageId', 0)
        this.resetStorageInfo()
      }
    },
    storageId (newVal) {
      if (newVal && this.visible) {
        this.getStorageDetail()
      } else {
        this.$emit('update:storageId', 0)
        this.resetStorageInfo()
      }
    }
  },
  data () {
    return {
      name: '',
      location: {
        initialization: false,
        province: '',
        provName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: ''
      },
      address: '',
      coverList: [],
      uploadPicVisible: false,
      imageList: []
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 获取默认图
    getDefaultCover (arr) {
      for (const item of arr) {
        if (item.isDefault) {
          return item
        }
      }
      return arr[0]
    },
    onModalClose (status) {
      if (!status) {
        this.resetStorageInfo()
        this.$emit('change', false)
      }
    },
    // 重置仓库信息
    resetStorageInfo () {
      this.name = ''
      this.location = {
        initialization: false,
        province: '',
        provName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: ''
      }
      this.address = ''
      this.coverList = []
    },
    // 点击确定
    onClickConfirmPic () {
      this.coverList = this.imageList.map(item => item)
      this.uploadPicVisible = false
    },
    // 点击取消
    onClickCanclePic () {
      this.imageList = this.coverList.map(item => item)
      this.uploadPicVisible = false
    },
    // 上传照片
    uploadPic () {
      this.imageList = this.coverList.map(item => item)
      this.uploadPicVisible = true
    },
    // 上传图片成功
    uploadCoverSuccess (arr) {
      arr.forEach(item => {
        this.imageList.push({
          url: item.url,
          width: item.width,
          height: item.height,
          isDefault: this.imageList.length === 0
        })
      })
    },
    // 点击确定
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请填写仓库名称')
      let params = {
        storageName: this.name,
        coverList: this.coverList.map(item => {
          return {
            url: item.url,
            width: item.width,
            height: item.height,
            extension: JSON.stringify({ isDefault: item.isDefault })
          }
        })
      }
      if (this.location.province) {
        params.provCode = this.location.province
        params.prov = this.location.provName
        params.cityCode = this.location.city
        params.city = this.location.cityName
        params.distCode = this.location.area
        params.dist = this.location.areaName
        params.address = this.address
      }
      if (this.storageId) {
        editStorage(this.storageId, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('修改成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        createStorage(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('新建成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
    },
    // 获取仓库详情
    getStorageDetail () {
      getStorageDetail(this.storageId).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.name = data.name
          this.location = {
            initialization: true,
            province: data.provCode.toString(),
            provName: data.prov.toString(),
            city: data.cityCode.toString(),
            cityName: data.city.toString(),
            area: data.distCode.toString(),
            areaName: data.dist.toString()
          }
          this.address = data.address
          this.coverList = data.coverList.map(item => {
            return {
              url: item.url,
              width: item.width,
              height: item.height,
              isDefault: item.extension ? JSON.parse(item.extension).isDefault : ''
            }
          })
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.createStorage {
  display: flex;
  flex-direction: column;
  align-items: center;
  .createStorage-form {
    position: relative;
    .createStorage-li {
      display: flex;
      margin-top: 30px;
      p {
        width: 80px;
        font-size: 16px;
        line-height: 32px;
        text-align: right;
        margin-right: 10px;
      }
    }
    .createStorage-pic {
      // flex-direction: column;
      // position: absolute;
      // top: 0;
      // right: 0;
      cursor: pointer;
      p {
        text-align: left;
      }
      .createStorage-pic-upload {
        width: 160px;
        height: 120px;
        border: 1px dashed rgba(0, 0, 0, 0.24);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .createStorage-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.uploadPic {
  padding-left: 20px;
  .uploadPic-title {
    font-size: 16px;
  }
  .uploadPic-btn {
    display: flex;
    justify-content: center;
  }
}
</style>