<template>
  <div @click="handleClick">
    <input :accept="accept"
           :multiple="multiple"
           :webkitdirectory="webkitdirectory"
           @change="handleChange"
           class="input-style"
           ref="input"
           type="file">
    <slot></slot>
  </div>
</template>

<script>
import { analysisWord } from '../../services/utilsapi';
import OssUpload from '../../utils/ossupload'
import config from '../../config/constantconfig'

const uuidv1 = require('uuid/v1')
export default {
  props: {
    accept: {
      type: String,
      default: '.txt,.docx'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    webkitdirectory: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick () {
      if (this.disabled) return
      this.$refs.input.click()
    },
    handleChange (e) {
      const files = e.target.files
      if (!files) return
      const file = e.target.files[0]
      let type = this.getFileSuffix(file.name)
      if (type !== '.txt' && type !== '.docx') {
        this.$Message.info("文件类型错误,请重新选择文件")
        return
      }
      this.$emit("import-start")
      if (type === '.txt') {
        this.impTxt(file)
      } else {
        this.impWord(file)
      }
      this.$refs.input.value = null
    },
    impTxt (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        let str = e.target.result.trim()
        str = str.replace(/http/g, '\nhttp').replace(/http*[^\s]*\?code=/g, "\n").replace(/null/g, '')
        let arr = str.split(/[(\r\n)\r\n]+/)
        let newArr = arr.filter(item => {
          return item.trim()
        })
        this.$emit("import-success", newArr)
      }
      reader.onerror = (err) => {
        console.log(err);
        this.$emit("import-error")
      }
      reader.readAsText(file, "utf-8")
    },
    impWord (file) {
      this.upload(file).then(res => {
        let url = res.url
        analysisWord({ fileUrl: url }).then(res => {
          if (res.code === 0) {
            let str = res.data.data.trim()
            str = str.replace(/http/g, '\nhttp').replace(/[http||https][^\s]*\?code=/g, "\n").replace(/null/g, '')
            console.log(str);
            let arr = str.split(/[(\r\n)\r\n]+/)
            console.log(arr);
            let newArr = arr.filter(item => {
              return item.trim()
            })
            this.$emit("import-success", newArr)
          } else {
            this.$emit("import-error")
          }
        }).catch(err => {
          console.log(err);
          this.$emit("import-error")
        })
      }).catch(err => {
        console.log(err);
        this.$emit("import-error")
      })
    },
    getUploadPath () {
      let uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + '/qrcode/trace/'
      return uploadPath
    },
    upload (file) {
      let uuid = uuidv1()
      let uploadPath = this.getUploadPath()
      let name = this.getFileSuffix(file.name)
      return OssUpload.put(uploadPath + uuid + name, file).then(r1 => {
        let region = config.imageOssInfo.severurl
        let domain = config.imageOssInfo.url
        return {
          'name': r1.name,
          'url': r1.url.replace(region, domain)
        }
      }, () => {
        return {
          'name': file.name,
          'url': 'error_url'
        }
      })
    },
    getFileSuffix (name) {
      let resultstr = ''
      let index = name.lastIndexOf('.')
      if (index > 0) {
        resultstr = name.substring(index)
      }
      return resultstr
    }
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
